<template>
  <div class="container pt-3" ref="mainView">
    <div class="row" style="margin: 0 auto; padding-left: 1.3vw; margin-bottom: 10px">
      <div v-for="(value, name, index) in games" :key="index" class="col-6 m-0 p-0">
        <div class="mainlink">
          <div class="imglink" :style="
            'background-image: url(/images/home/games/' +
            name +
            '.svg);width:' +
            bannerWidth +
            'px;height:' +
            bannerheight +
            'px'
          ">
            <div v-if="value.badges.includes('favorite')" class="favorite"></div>
            <div v-if="value.badges.includes('new')" class="new"></div>
            <div class="buttons" v-for="(chain, game) in blockchains[name]" :key="chain.name + game">
              <router-link :style="
                'top:' +
                bannerheight * 0.7 +
                'px; background-color:' +
                chain.color +
                ';color:' +
                chain.text +
                ''
              " :to="'/' + name + '/' + chain.name" class="chainLinks ms-1">
                {{ chain.display_name }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AvailableChains from "../config/index.js";
export default {
  data() {
    return {
      games: this.$store.getters.getConfig.games,
      bannerWidth: 0,
      bannerheight: 0,
      blockchains: {},
    };
  },
  created() {
    window.addEventListener("resize", this.containerSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.containerSize);
  },
  mounted() {
    this.containerSize();
    this.getblockchains();
  },
  methods: {
    containerSize() {
      try {
        this.bannerWidth = this.$refs.mainView.clientWidth / 2.25;
        this.bannerheight = this.bannerWidth * 0.9375;
      } catch (error) {
        console.log(error)
      }

    },
    getblockchains() {
      for (const chain in AvailableChains) {
        for (const game in AvailableChains[chain].games) {
          if (!AvailableChains[chain].games[game].active) {
            continue;
          }

          if (typeof this.blockchains[game] === "undefined") {
            this.blockchains[game] = [];
          }

          this.blockchains[game].push({
            name: AvailableChains[chain].name,
            display_name: AvailableChains[chain].display_name,
            color: AvailableChains[chain].view.colors["chain-color"],
            text: AvailableChains[chain].view.colors["tabtxtselected-color"],
            new: AvailableChains[chain].games[game].new,
          });
        }
      }
    },
  },
};
</script>
<style scoped>
.imglink {
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  margin-bottom: 5px;
  position: relative;
}

.chainLinks {
  position: relative;
  padding: 5px 8px;
  border-radius: 2px;
  font-size: 80%;
}

.buttons {
  display: inline-block;
}

.new {
  background-image: url("/images/new.svg");
  min-width: 48px;
  min-height: 45px;
  background-repeat: no-repeat;
  position: absolute;
  right: 0px;
}

.favorite {
  background-image: url("/images/hot.svg");
  min-width: 48px;
  min-height: 45px;
  background-repeat: no-repeat;
  position: absolute;
  right: 0px;
}

@media (min-width: 992px) {
  .chainLinks {
    padding: 10px 20px;
    font-size: 20px;
    margin-right: 10px;
    top: 260px !important;
    border-radius: 5px;
  }

  .chainLinks:last-child {
    margin-right: 0px;
  }

  .new {
    min-width: 80px;
    min-height: 77px;
  }
  .favorite {
    min-width: 80px;
    min-height: 77px;
  }
}
</style>